<template>
  <CSidebar
    :visible="isVisible"
    position="fixed"
    @visible-change="(value: boolean) => emits('sidebarVisibleChanged', value)"
  >
    <div class="tw-w-[inherit] tw-border-r tw-border-r-purple-200 tw-flex-grow">
      <CSidebarHeader>
        <div class="tw-w-48 tw-flex-auto">
          <OrganizationDropdown
            :select-options="organizationOptions"
            :is-loading="isLoadingOrganizationData"
          />
        </div>
      </CSidebarHeader>
      <CSidebarNav>
        <div class="tw-overflow-y-auto">
          <div
            v-for="navigationItem in navigationItems"
            :key="navigationItem.routeName"
          >
            <CNavGroup
              v-if="navigationItem.childItems"
              :visible="isAnyChildRouteActive(navigationItem.childItems)"
            >
              <template #togglerContent>
                <CIcon
                  custom-class-name="nav-icon"
                  :icon="navigationItem.icon"
                />
                <span class="tw-uppercase tw-font-semibold">{{
                  $t(navigationItem.translationKey)
                }}</span>
              </template>
              <CNavItem
                v-for="navigationItemChild in navigationItem.childItems"
                :key="navigationItemChild.routeName"
                :href="
                  router.resolve({ name: navigationItemChild.routeName }).href
                "
                :active="isRouteActive(navigationItemChild.routeName)"
                @click="navigateTo({ name: navigationItemChild.routeName })"
              >
                <CIcon custom-class-name="nav-icon" icon="nav-icon-bullet" />
                {{ $t(navigationItemChild.translationKey) }}
              </CNavItem>
            </CNavGroup>
            <CNavItem
              v-else
              :href="router.resolve({ name: navigationItem.routeName }).href"
              :active="isRouteActive(navigationItem.routeName)"
              @click="navigateTo({ name: navigationItem.routeName })"
            >
              <CIcon custom-class-name="nav-icon" :icon="navigationItem.icon" />
              {{ $t(navigationItem.translationKey) }}
            </CNavItem>
          </div>
        </div>
      </CSidebarNav>
      <CSidebarFooter class="border-top tw-sticky">
        <CSidebarToggler @click="emits('sidebarTogglerClicked')" />
      </CSidebarFooter>
    </div>
  </CSidebar>
</template>

<script lang="ts" setup>
import {
  cilAvTimer,
  cilBullhorn,
  cilClone,
  cilCode,
  cilContact,
  cilViewQuilt,
  cilBuilding,
} from '@coreui/icons'
import UserService from '~/services/MSAPI/user'
import type { NGetOrganizationsByUserId } from '~/services/MSAPI/user/user/types'

interface IProps {
  isVisible: boolean
  hasOrganizationInRoute: boolean
}

interface INavigationItem {
  routeName: string
  translationKey: string
  icon?: string[]
  childItems?: INavigationItem[]
}

const props = defineProps<IProps>()

const route = useRoute()
const router = useRouter()
const userService = new UserService()
const authStore = useAuthStore()

const emits = defineEmits<{
  (e: 'sidebarTogglerClicked'): void
  (e: 'sidebarVisibleChanged', value: boolean): void
}>()

const isRouteActive = (routeName: string) => {
  return routeName === route.name
}

const isAnyChildRouteActive = (childItems: typeof navigationItems) => {
  return childItems.some((item) => isRouteActive(item.routeName))
}

const navigationItems = reactive<INavigationItem[]>([
  {
    routeName: 'organizationId-dashboard',
    translationKey: 'general.header.navs.dashboard',
    icon: cilViewQuilt,
  },
  {
    routeName: '', // Definir
    translationKey: 'general.header.navs.audience',
    icon: cilContact,
    childItems: [
      {
        routeName: '', // Definir
        translationKey: 'general.header.dropdownItems.audience',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.dropdownItems.createAudience',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.dropdownItems.segmentation',
      },
    ],
  },
  {
    routeName: '', // Definir
    translationKey: 'general.header.navs.campaign',
    icon: cilBullhorn,
    childItems: [
      {
        routeName: '', // Definir
        translationKey: 'sender.domainSender.domainSenderList.status.createNew',
      },
      {
        routeName: '', // Definir
        translationKey:
          'sender.domainSender.domainSenderList.status.campaignList',
      },
    ],
  },
  {
    routeName: '', // Definir
    translationKey: 'general.header.navs.automation.navTitle',
    icon: cilAvTimer,
    childItems: [
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.automation.createNew',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.automation.list',
      },
    ],
  },
  {
    routeName: '', // Definir
    translationKey: 'general.header.navs.api',
    icon: cilCode,
    childItems: [
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.transational-documentation',
      },
      {
        routeName: 'organizationId-api-transactions', // Definir
        translationKey: 'general.header.navs.transational-transational-log',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.transational-apiKey',
      },
    ],
  },
  {
    routeName: '', // Definir
    translationKey: 'general.header.navs.templates',
    icon: cilClone,
    childItems: [
      {
        routeName: 'organizationId-template-sms',
        translationKey: 'general.header.navs.smsTemplate',
      },
      {
        routeName: 'organizationId-template-email',
        translationKey: 'general.header.navs.emailTemplate',
      },
    ],
  },
  {
    routeName: '', // Definir
    translationKey: 'general.header.navs.organization',
    icon: cilBuilding,
    childItems: [
      {
        routeName: 'organizationId-organization-details',
        translationKey: 'general.header.navs.organizationDetails',
      },
      {
        routeName: 'organizationId-organization-contacts',
        translationKey: 'general.header.navs.contacts',
      },
    ],
  },
])

const organizationListQueryParams = {
  includeDescendentOrganizations: true,
  page: 1,
  count: 99999,
} as NGetOrganizationsByUserId.IParams

const { data: organizationListData, isLoading: isLoadingOrganizationData } =
  useQuery({
    queryKey: ['organizationListHeader'],
    queryFn: () =>
      userService.user.getOrganizationsByUserId(
        authStore.userData?.userId as string,
        organizationListQueryParams,
      ),
    enabled: () =>
      props.hasOrganizationInRoute && Boolean(authStore.userData?.userId),
  })

const organizationOptions = computed(() => {
  const mappedOptions = organizationListData.value?.data?.items.map(
    ({ organizationId, companyName }: NGetOrganizationsByUserId.IItem) => {
      return {
        id: organizationId,
        name: companyName,
      }
    },
  )

  return mappedOptions || []
})
</script>

<style></style>
