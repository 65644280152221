import NotificationControllerBase from '../base'
import type {
  NGetNotification,
  NGetNewNotificationFromLast,
  NClearAllNotifications,
  NClearNotification,
} from './types'
import type { TResponseBase } from '~/services/MSAPI/types'

export default class NotificationControllerNotification extends NotificationControllerBase {
  static readonly controllerPath = 'notification'
  private readonly serviceUrl: string

  constructor() {
    super(NotificationControllerNotification.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getNotification(
    limit: number,
    count: number,
  ): TResponseBase<NGetNotification.IResponseData[]> {
    return await useFetchInstance(`${this.serviceUrl}/${limit}/${count}`, {
      method: 'GET',
    })
  }

  async getNewNotificationFromLast(
    lastNotificationId: string,
  ): TResponseBase<NGetNewNotificationFromLast.IResponseData[]> {
    return await useFetchInstance(
      `${this.serviceUrl}/get-new/${lastNotificationId}`,
      {
        method: 'GET',
      },
    )
  }

  async clearNotification(
    notificationId: string,
  ): TResponseBase<NClearNotification.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/${notificationId}`, {
      method: 'DELETE',
    })
  }

  async clearAllNotifications(): TResponseBase<NClearAllNotifications.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}`, {
      method: 'DELETE',
    })
  }

  async markAsReadNotifications(
    notificationIds: string[],
  ): TResponseBase<NClearAllNotifications.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/mark-as-read`, {
      method: 'PATCH',
      body: notificationIds,
    })
  }
}
