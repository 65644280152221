<template>
  <div class="tw-sticky tw-top-0 tw-z-50">
    <CHeader
      container="fluid"
      class="tw-shadow-md tw-min-h-20"
    >
      <div class="d-flex tw-gap-1 tw-items-center">
        <CIcon
          v-if="hasOrganizationInRoute"
          as="button"
          :icon="cilHamburgerMenu"
          size="xl"
          class="tw-cursor-pointer tw-mr-2"
          @click="emits('hamburguerMenuClicked')"
        />
        <CHeaderBrand as="button" class="!tw-mr-0" @click="handleBrandRedirect">
          <NuxtImg src="img/logo-color.svg" width="148px" height="52px" />
        </CHeaderBrand>
      </div>
      <div>
        <NotificationDropdown class="sm:tw-mr-2" />
        <AvatarDropdown />
      </div>
    </CHeader>
  </div>
</template>

<script lang="ts" setup>
import { cilHamburgerMenu } from '@coreui/icons'
// const organizationListQueryParams = {
//   includeDescendentOrganizations: true,
//   page: 1,
//   count: 9999, // Precisa ser um número alto, pois deve carregar todas as organizações do utilizador. Caso seja um Sendit, haverão muitas organizações listadas
// }
interface IProps {
  hasOrganizationInRoute: boolean
}

const props = defineProps<IProps>()
const route = useRoute()
const emits = defineEmits<{
  (e: 'hamburguerMenuClicked'): void
}>()

const handleBrandRedirect = () => {
  const navigateObj = props.hasOrganizationInRoute
    ? {
        name: 'organizationId-dashboard',
        params: { organizationId: route.params.organizationId },
      }
    : { name: 'select-organization' }
  return navigateTo(navigateObj)
}
</script>
