<script setup lang="ts">
import VueMultiselect from 'vue-multiselect'
import UserService from '~/services/MSAPI/user'
import BalanceService from '~/services/MSAPI/balance'
import type { EPlanType } from '~/constants/organizationPlan';

const userService = new UserService()
const balanceService = new BalanceService()
const authStore = useAuthStore()

interface ISelectOption {
  id: string
  name: string
}

interface IProps {
  selectOptions: ISelectOption[] | []
  isLoading: boolean
}

const props = defineProps<IProps>()

const route = useRoute()

const organizationIdRouteAsOption = computed(() => {
  const organizationId = route.params.organizationId
  const matchedOrganization = props.selectOptions.find(
    (option) => option.id === organizationId,
  )
  return {
    id: organizationId,
    name: matchedOrganization?.name,
  }
})

const selected = ref(organizationIdRouteAsOption)

const isDisabled = computed(
  () => props.isLoading || props.selectOptions.length === 0,
)

const selectOrganization = async (value: ISelectOption) => {
  try {
    const session = await userService.user.getSession(value.id)
    const planRequest = await balanceService.organizationPlan.get(value.id)

    authStore.setUserSession(session.data.user)
    authStore.setOrganizationSession(session.data.organization)
    authStore.setOrganizationPlanSession(
      planRequest.data.plan?.name as string,
      planRequest.data.plan?.planType as EPlanType,
    )
  } catch (e) {
    console.error('error', e)
  }

  await navigateTo({
    // name: route.name || 'organizationId-dashboard', // Por definição, ao selecionar uma organização deve ser direcionado para o dashboard
    name: 'organizationId-dashboard',
    params: { organizationId: value.id },
  })
}
</script>

<template>
  <div>
    <span
      class="tw-block tw-uppercase tw-text-sm tw-font-medium tw-text-gray-400 tw-mb-1"
      >{{
        $t('organization.subOrganization.subOrganizationList.organization')
      }}</span
    >
    <VueMultiselect
      v-model="selected"
      :options="props.selectOptions"
      :clear-on-select="false"
      :show-labels="false"
      :allow-empty="false"
      :loading="props.isLoading"
      :disabled="isDisabled"
      :show-no-options="false"
      close-on-select
      :placeholder="$t('organization.dropdown.select.placeholder')"
      label="name"
      track-by="id"
      @select="selectOrganization"
    />
  </div>
</template>
