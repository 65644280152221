<template>
  <nav aria-label="breadcrumb">
    <CBreadcrumb>
      <CBreadcrumbItem
        v-for="(item, index) in mappedItems"
        :key="index"
        :active="item.active"
        :href="item.href"
      >
        <NuxtLink
          class="tw-text-inherit"
          :class="{ 'tw-no-underline tw-pointer-events-none': item.active }"
          :to="{ name: item.routeName }"
          >{{ item.name }}</NuxtLink
        >
      </CBreadcrumbItem>
    </CBreadcrumb>
  </nav>
</template>

<script lang="ts" setup>
export interface IItem {
  nameTranslationKey: string
  routeName?: string
}
interface IProps {
  items: IItem[]
}

const props = defineProps<IProps>()
const { t } = useI18n()
const route = useRoute()

const checkItemActive = (itemRouteName?: string) => {
  if (!itemRouteName) return false

  return route.name === itemRouteName
}

const mappedItems = computed(() => {
  return props.items.map((item) => {
    const isItemActive = checkItemActive(item.routeName)
    return {
      name: t(item.nameTranslationKey),
      routeName: item.routeName,
      active: isItemActive,
      href: isItemActive ? undefined : '#',
    }
  })
})
</script>

<style></style>
