<script lang="ts" setup>
import UserService from '~/services/MSAPI/user'

const authStore = useAuthStore()

// TODO: Adicionar route names
const navigationItems = reactive([
  {
    routeName: 'settings',
    translationKey: 'sender.domainSender.domainSenderList.status.settings',
    click: () =>
      navigateTo({ name: 'organizationId-settings-user-basic-info' }),
  },
  {
    routeName: 'organizationId-help',
    translationKey: 'general.header.navs.settings.help',
    click: () => navigateTo({ name: 'organizationId-help' }),
  },
  {
    routeName: 'auth-login',
    translationKey: 'sender.domainSender.domainSenderList.status.logout',
    click: authStore.logout,
  },
])

const nameInitials = computed(() => {
  const name = authStore.userData?.name
  if (!name) return '-'

  const nameParts = name.split(' ')
  const firstInitial = nameParts[0].charAt(0).toUpperCase()

  if (nameParts.length > 1) {
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    return firstInitial + lastInitial
  }

  return firstInitial
})

const avatarColor = computed(() =>
  hasUserProfileImage.value ? 'white' : 'gray-400',
)

const userService = new UserService()
const userProfileImageSrc = computed(() =>
  userService.user.getUserImageSmallURL(String(authStore.userData?.userId)),
)
const hasUserProfileImage = ref(false)
const isLoadingUserProfileImage = ref(false)

const checkUserProfileImageExistence = async () => {
  try {
    isLoadingUserProfileImage.value = true
    await validateImageBySrc(userProfileImageSrc.value)
    hasUserProfileImage.value = true
  } catch (error) {
    hasUserProfileImage.value = false
  } finally {
    isLoadingUserProfileImage.value = false
  }
}

onMounted(() => {
  checkUserProfileImageExistence()
})
</script>

<template>
  <CDropdown aligment="start" placement="top-end" class="tw-cursor-pointer">
    <CDropdownToggle class="!tw-border-0" :caret="false">
      <CSpinner v-if="isLoadingUserProfileImage" size="sm" color="primary" />
      <CAvatar v-else :color="avatarColor" text-color="white" size="md">
        <NuxtImg
          v-if="hasUserProfileImage"
          preload
          class="tw-rounded-[50em] tw-bg-white"
          alt="userImage"
          :src="userProfileImageSrc"
        />
        <span v-else>{{ nameInitials }}</span>
      </CAvatar>
      <CIcon use="/img/chevron-down.svg#chevron-down" />
    </CDropdownToggle>
    <CDropdownMenu class="tw-shadow-xl !tw-py-0">
      <CDropdownItem
        v-for="navigationItem in navigationItems"
        :key="navigationItem.routeName"
        component="<button>"
        as="div"
        class="tw-transition-colors tw-delay-75 tw-normal-case !tw-p-3 !tw-text-gray"
      >
        <div @click="navigationItem.click">
          {{ $t(navigationItem.translationKey) }}
        </div>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<style></style>
