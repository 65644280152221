<template>
  <div>
    <TheNavSidebar
      v-if="hasOrganizationInRoute"
      :is-visible="isNavSidebarVisible"
      :has-organization-in-route="hasOrganizationInRoute"
      @sidebar-toggler-clicked="hideNavSidebar"
      @sidebar-visible-changed="handleNavSidebarVisibility"
    />
    <div class="tw-bg-gray-100 tw-pl-[var(--cui-sidebar-occupy-start)] tw-transition-[padding]">
      <TheHeader
        :has-organization-in-route="hasOrganizationInRoute"
        @hamburguer-menu-clicked="toggleNavSidebar"
      />
      <div
        :class="{ 'container tw-my-11': useContainer }"
        class="tw-m-auto tw-w-full tw-min-h-screen"
      >
        <TheRouteBreadcrumb
          v-if="hasPageBreadcrumb"
          :items="route.meta.pageBreadcrumbItems || []"
        />
        <slot />
      </div>
      <TheFooter />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface IProps {
  useContainer?: boolean
}
defineProps<IProps>()
const route = useRoute()
const isNavSidebarVisible = ref(true)

const hasOrganizationInRoute = computed(() =>
  Boolean(route.params.organizationId),
)

const hasPageBreadcrumb = computed(
  () =>
    route.meta.pageBreadcrumbItems && route.meta.pageBreadcrumbItems.length > 0,
)

const hideNavSidebar = () => (isNavSidebarVisible.value = false)
const handleNavSidebarVisibility = (value: boolean) =>
  (isNavSidebarVisible.value = value)
const toggleNavSidebar = () =>
  (isNavSidebarVisible.value = !isNavSidebarVisible.value)
</script>
