import BaseService from "../base"

export default class NotificationControllerBase extends BaseService {
  static readonly basePath = 'notification/api/v1.0'
  private readonly controllerPath: string

  constructor(controllerPath: string) {
    super(NotificationControllerBase.basePath)
    this.controllerPath = controllerPath
  }

  protected getFullServiceUrl() {
    return `${this.getFullBaseUrl()}/${this.controllerPath}`
  }
}
